import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VersionManagerService {
  dummyVersion = '1.0.0';

  // Compare versions and handle the result
  handleVersionCheck(newVersion: string): void {
    if (newVersion) {
      if (newVersion === this.dummyVersion) {
        return;
      } else {
        this.clearCacheAndReload();
      }
    }
  }

  // Clear cache and perform a hard reload
  clearCacheAndReload(): void {
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        return Promise.all(cacheNames.map((cache) => {
          return caches.delete(cache);
        }));
      })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          window.location.reload();
        });
    } else {
      console.warn('Caches not supported in this browser.');
    }
  }

}
