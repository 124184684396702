import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MedicineConfigService } from '../../../../services/medicine-config.service';
import {ConfigurationService} from '../../../../services/configuration.service';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-medicine-administration',
  templateUrl: './medicine-administration.component.html',
  styleUrls: ['./medicine-administration.component.css'],
})
export class MedicineAdministrationComponent implements OnInit {
  @Input() patient: any;
  @Input() isAdd: boolean;
  @Output() isSubmitDiscontinuedforListing = new EventEmitter<string>();
  medicineDate: any;
  forFrequency = true;
  medicineAdministration: any;
  intervalId: any;
  calculatedDose: any;
  isDisable = false;
  showReqMedDirectlyOnAdmin = false;
  medicationId: any;
  userPassword = '';
  empId = '';
  isConfimed = false;
  medicineDetail: any;
  isConfimedDiscontinue = false;
  isSubmitDiscontinued = false;
  isShowDiscontinued = false;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private medicineConfigService: MedicineConfigService,
    private configService: ConfigurationService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): any {
      this.getMedicineAdministration('');
  }
  getMedicineAdministration(val): any {
    this.ngxLoader.start();
    this.medicineConfigService
      .getmedicineAdministration(
        this.patient?.id,
        this.patient?.visit_id,
        $('#medicine_date').val(),
        this.forFrequency,
        true,
      )
      .subscribe(
        (data) => {
          this.medicineAdministration = data;
          this.ngxLoader.stop();
        },
        (error) => {
          this.ngxLoader.stop();
        },
      );
  }
  printMedicineAdministration(): any {
    const URL =
      '/pdf-reports/medicine-administration-v2?id=' +
      this.patient.id +
      '&visit_id=' +
      this.patient.visit_id +
      '&mrn=' +
      this.patient.mrn +
      '&invoice_id=';
    window.open(URL, '_blank');
  }
  showTransfusionConfirmationModal(value, data): any {
    if (((data?.issued_quantity - data?.total_administrate_qty) < value?.interval_qty) && !this.showReqMedDirectlyOnAdmin && !data.calculated_dose){
      toastr.error('Issue Quantity is not equal to Interval Quantity');
      return;
    }
    this.intervalId = value.id;
    this.medicationId = data.id;
    this.calculatedDose = data.calculated_dose;
    this.isDisable = data.is_dose_discontinue;
    if (!this.isDisable) {
      $('#transfusionMedicineModal').modal('show');
      this.empId = '';
      this.userPassword = '';
      this.isConfimed = false;
    } else {
      toastr.error('Selected Medicine Is Discontinued');
    }
  }
  confirmTransfusion(): any {
    // this.isSubmit = true;
    if (!this.empId) {
      toastr.error('add Employee Id');
      return;
    }
    if (!this.userPassword) {
      toastr.error('add Password');
      return;
    }
    if (!this.isConfimed) {
      toastr.error('select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService
      .confirmTransfusionMedicine(
        this.intervalId,
        this.medicationId,
        this.patient.id,
        this.patient.visit_id,
        this.empId,
        this.userPassword,
        this.calculatedDose,
        $('#medicine_date').val(),
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success('Medicine Administered successfully');
          $('#transfusionMedicineModal').modal('hide');
          this.getMedicineAdministration('');
        },
        (error) => {
          this.ngxLoader.stop();
          toastr.error(error.error.message);
        },
      );
  }
  showDetailModal(data): any {
    this.medicationId = data.id;
    this.medicineDetail = '';
    $('#historyDetail').modal('show');
    this.getmedicineDetail();
  }
  showDiscontinueConfirmationModal(data): any {
    this.medicationId = data.id;
    $('#discontinueMedicineModal').modal('show');
    this.empId = '';
    this.userPassword = '';
    this.isConfimedDiscontinue = false;
  }
  getmedicineDetail(): any {
    this.ngxLoader.start();
    this.medicineConfigService
      .getmedicineDetail(this.medicationId, $('#medicine_date').val())
      .subscribe(
        (data) => {
          this.medicineDetail = data.data;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          $('#historyDetail').modal('hide');
        },
      );
  }
  confirmDiscontinue(): any {
    if (!this.empId) {
      toastr.error('Add Employee Id');
      return;
    }
    if (!this.userPassword) {
      toastr.error('Add Password');
      return;
    }
    if (this.isConfimedDiscontinue) {
      this.ngxLoader.start();
      this.medicineConfigService
        .confirmDiscontinueMedicine(
          this.medicationId,
          this.patient.id,
          this.patient.visit_id,
          this.empId,
          this.userPassword,
        )
        .subscribe(
          (data) => {
            this.ngxLoader.stop();
            $('#discontinueMedicineModal').modal('hide');
            toastr.success('Medicine Discontinued Successfully');
            this.isSubmitDiscontinued = true;
            // @ts-ignore
            this.isSubmitDiscontinuedforListing.emit(this.isSubmitDiscontinued);
            this.getMedicineAdministration('');
          },
          (error) => {
            this.ngxLoader.stop();
            toastr.error(error.error.message);
          },
        );
    } else {
      toastr.error('Select Confirmation');
    }
  }
  getShowMedicinesInIpd(): any {
    this.ngxLoader.start();
    this.configService.getShowMedicinesInIpd().subscribe(
      (data) => {
        this.showReqMedDirectlyOnAdmin = data?.show_req_med_directly_on_admin;
        console.log('isIpdMedicineConfigurator', this.showReqMedDirectlyOnAdmin);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  ngOnInit(): void {
    if (this.isAdd === true){
      this.getMedicineAdministration('');
    }
    this.getShowMedicinesInIpd();
  }
}
